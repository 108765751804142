.footer {
  //position: fixed;
  //bottom: 0;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px;

   //position: fixed;
   //left: 0;
   //bottom: 0;
   //width: 100%;
   ////background-color: white;
   ////color: white;
   //text-align: center;

  //position: static;
  //bottom: 0;




  //.logoTelegram{
  //  width: 50px;
  //  height: 50px;
  //}

  .logo {
    //font-weight: 700;
    color: #e4622a;
    text-decoration: none;
     font-weight: 800;
      font-size: 20px;
  }
   .in {
      font-weight: 800;
      font-size: 20px;
      color: #e4622a;
      //line-height: 75px;
     text-decoration: none;
      border: 1px solid #e4622a;
      padding: 5px 8px;

       &:hover {
        color: white;
        background: #e4622a;
      }
    }
  .contacts{
     font-weight: 600;
        font-size: 20px;

    .phone{
     padding-left: 32px;
    }
  }

}
@media (max-width: 767px){
  .footer{
    max-width: 290px;
    .contacts{
      font-size: 15px;
      font-weight: 400;
    }
  }
}