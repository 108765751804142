.about {
  margin-top: 80px;

  .aboutWrapper {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid black;
    padding-bottom: 80px;

    .photoAbout {
      max-width: 500px;
      height: 500px;
      margin-left: 20px;
    }

    .aboutHeader {
      margin-left: 50px;
      margin-right: 20px;
      animation: slideLeft 1s linear forwards;


      //      .typing {
      //  /* font-family: "Inconsolata", Consolas, monospace; */
      //  position: relative;
      //  &::after {
      //    content: "|";
      //    position: absolute;
      //    right: 0;
      //    width: 100%;
      //    background: #fff;
      //    animation: typing 3s steps(var(--steps)) forwards, caret 1s infinite;
      //
      //
      //
      //  }
      //
      //
      //}

      .typing {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        border-right: .10em solid #e4622a;
        animation: typing 3s steps(30, end), blink-caret .75s step-end infinite;
      }

      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 70%;
        }
      }

      @keyframes blink-caret {
        from, to {
          border-color: transparent;
        }
        50% {
          border-color: #e4622a;
        }
      }


      @keyframes slideLeft {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }

      h3 {
        font-weight: 400;
        font-size: 60px;
        color: #e4622a;
      }

      p {
        font-weight: 400;
        font-size: 20px;
      }

      .upper {
        text-transform: uppercase;
      }

      button {
        padding: 20px 30px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 300;
        font-size: 20px;
        color: black;
        background: white;
        border: 1px solid black;
        margin-top: 16px;

        &:hover {
          color: white;
          background: black;

        }
      }
    }
  }

  .education {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    h3 {
      font-weight: 400;
      font-size: 60px;
      color: #e4622a;
    }

    .educationLeft {
      margin-top: 36px;

      h5 {
        font-weight: 800;
        font-size: 30px;
        color: black;

      }

      p {
        font-size: 30px;
        text-decoration: underline;
      }
    }

  }

  .mainAbout {
    background-color: #e4622a;
    color: white;

    .mainAboutWrapper {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      //animation: slideLeft 1s linear forwards;
      //
      //
      //@keyframes slideLeft {
      //  0% {
      //    transform: translateX(100%);
      //  }
      //  100% {
      //    transform: translateX(0);
      //  }
      //}


      p {
        font-weight: 500;
        font-size: 30px;
        padding: 30px;
      }

      li {
        list-style-type: none;
        font-weight: 300;
        font-size: 30px;
        padding: 20px 0;
      }
    }
  }

}

@media (max-width: 767px) {
  .about {
    overflow: hidden;


    .aboutWrapper {
      max-width: 290px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      .aboutHeader {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .photoAbout {
        max-width: 290px;
        height: 290px;
      }


    }

    .education {
      margin: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 30px;
        margin: 20px 0 0 0;
      }

      .educationLeft {
        margin: 0;

        h5 {
          font-size: 20px;
          text-align: center;
        }
      }
    }

    .mainAbout {
      max-width: 100%;

      .mainAboutWrapper {
        margin: 40px 20px;
        flex-direction: column;
        align-items: center;

        p {
          font-size: 20px;
          font-weight: 800;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            padding: 5px;
            font-weight: 300;
            font-size: 20px;
          }
        }
      }
    }
  }


}
