.home {


  .animation-started .homeHeader {
    animation-play-state: paused;
  }

  .homeHeader {
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    text-transform: uppercase;
    padding: 80px 0 80px 0;

    position: relative;
    overflow: hidden;

    .background-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    .tittleHome {
      position: relative;
      z-index: 1;

      animation: slideRight 2s linear 1;


      @keyframes slideRight {
        0% {
          transform: translateX(-100%);
        }
        50% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(0);
        }
      }

      h4 {
        color: #050505;
        font-weight: 400;
        font-size: 40px;

      }

      h1 {
        font-weight: 600;
        font-size: 70px;
      }

      p {
        margin: 48px 0;
      }

      .btn {
        padding: 20px 30px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 300;
        font-size: 20px;
        text-decoration: none;
      }

      .btnLeft {
        color: white;
        background: #e4622a;
        border: 1px solid #e4622a;


        &:hover {
          color: #e4622a;
          background: white;
          transition: color 2s ease, background 2s ease;

        }
      }

      .btnRight {
        color: black;
        background: white;
        border: 1px solid black;
        margin-left: 30px;

        &:hover {
          color: white;
          background: black;
          transition: color 2s ease, background 2s ease;

        }
      }
    }

    .photoMain {
      position: relative;
      z-index: 1;

      img {
        max-width: 500px;
        height: 500px;
      }

    }
  }

  .homeMain {

    position: relative;
    z-index: 0;

    .main-header {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      padding-top: 30px;

      .title2 {
        z-index: 4;
        font-weight: 400;
        font-size: 140px;
        padding: 30px 0 0 0;
        margin-left: 30px;
        color: #e4622a;
      }

      .additional-text {
        font-weight: 400;
        font-size: 20px;
        color: #777574;
      }
    }


    .homeMainWrapper {
      //position: relative;
      //z-index: 1;

      max-width: 1200px;
      margin: 0 auto;
      color: black;
      font-weight: 300;
      font-size: 40px;
          padding: 20px;


      .blur-bg {
        //position: absolute;
        //top: 0;
        //left: 0;
        width: 100%;
        height: 100vh;
        //overflow: hidden;
        //z-index: -1;
        display: flex;

      }


      .parallax {
        background-image: url("../../img/level.png");
        width: 100%;
        //min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 4;
      }


      .listHome {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-around;
        animation: slideRight 4s linear 1;
        //animation-fill-mode: forwards;


        @keyframes slideRight {
          0% {
            transform: translateX(-100%);
          }
          50% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(0);
          }
        }

        ul {
          margin-left: 20px;

          li {
            padding: 20px;
          }
        }


      }


    }
  }
}

@media (max-width: 767px) {
  .home {
    .homeHeader {
      margin: 0 auto;
      //max-width: 290px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .tittleHome {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        h1 {
          font-size: 40px;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }

      .photoMain {
        img {
          max-width: 290px;
          height: 290px;
        }

      }

      .btn_home {
        display: flex;

        .btn {
          padding: 12px 14px;
          font-size: 14px;
        }
      }

    }

    .homeMain {
      padding: 20px;

      .main-header {
        flex-direction: column;
        margin-bottom: 50px;

        .title2 {

          font-weight: 400;
          font-size: 60px;
          padding: 30px 0 0 0;
          margin-left: 30px;

        }

      }

      .homeMainWrapper {
        max-width: 290px;
        font-size: 20px;

        .blur-bg {
          flex-direction: column-reverse;
          height: 100%;
        }

        p {
          font-size: 20px;
        }

        .listHome {
          flex-direction: column;
        }

        ul {
          margin: 0;
        }

        li {
          padding: 10px;
        }
      }
    }
  }
}

