
.wrapper {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 100;

  .header {
    margin: 0 auto;
    padding: 30px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #050505;

    .img-logo {
      width: 80px;
      height: 80px;
      margin-top: 10px;
    }

    .in {
      font-weight: 800;
      color: #e4622a;
      line-height: 75px;
      border: 1px solid #e4622a;
      padding: 5px 8px;

      &:hover {
        color: white;
        background: #e4622a;
      }
    }

    .burger-icon {
      background: none;
      border: none;
      display: none;
      cursor: pointer;

      span {
        display: block;
        width: 30px;
        height: 4px;
        background: #050505;
        margin-bottom: 6px;
      }
    }

    .open .burger-icon span {
      background: #e4622a;
    }

    //.logo {
    //  font-weight: 700;
    //  color:#e4622a;
    //}

    .nav {
      ul {
        display: flex;
        align-items: center;

        li {
          margin: 0 30px;
          list-style-type: none;

          a {
            text-decoration: none;
            color: #050505;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;

            &:hover {
              color: #e4622a;
              border-bottom: 1px solid #e4622a;
              font-weight: 400;
            }
          }

          &.active a {
            color: #e4622a;
            border-bottom: 1px solid #e4622a;
            font-weight: 400;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header {
      padding:  20px 20px 0 20px;
      //width: 100%;
      overflow: hidden;

      .logo {
        display: flex;
        justify-content: center;
        width: 30px;
        height: 20px;
        align-items: center;


      }

      .linkedin {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border: 1px solid #e4622a;
        height: 30px;
        .in{
          border: none;
          &:hover {
        color: #e4622a;
        background: none;
      }
        }
      }

      .nav {
        display: none;
      }

      .burger-icon {
        display: block;
      }

      .open .burger-icon {
        color: #61dafb;
      }

      &.open .nav {
        overflow: hidden;
        //display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        border-bottom: 1px solid #050505;
        transition: margin-right 2s ease-in-out;
        display: flex;


        ul {
          //width: 100%;
          //align-items: flex-start;
          justify-content: center;
          width: 100%;
          padding: 0;
                  margin: 8px;

          li {
            margin: 5px;
          }
        }
      }
    }
  }
}

