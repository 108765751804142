.projects {
  max-width: 1200px;
  margin: 0 auto;
   padding: 20px;

  h3 {
    font-weight: 400;
    font-size: 60px;
    color: #e4622a;
    padding-bottom: 50px;
    //text-transform: uppercase;
    border-bottom: 1px solid black;
  }

  .floatLeft {
    animation: slideRight 2s linear 1;


    @keyframes slideRight {
      0% {
        transform: translateX(-100%);
      }
      50% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(0);
      }
    }

  }

  .floatRight {
    animation: slideLeft 1s linear forwards;


    @keyframes slideLeft {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  .cards {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;

    img {
      width: 350px;
      height: 250px;
      padding: 30px;
      border: 1px solid #e4622a;
      object-fit: cover;


      &:hover {
        border: 1px solid black;
      }
    }

    .tittleProject {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 600;
      color: #e4622a;

      &:hover {
        border-bottom: 1px solid black;
      }
    }

    p {
      font-weight: 300;
      font-size: 30px;
      padding: 20px 0;
    }
  }
}

@media (max-width: 767px) {
  .projects {
    max-width: 290px;
    margin: 0 auto;
    //margin-top: 100px;
    padding: 20px;
    overflow: hidden;

    h3 {
      font-size: 30px;
    }

    .cards {
      .tittleProject {
        font-size: 20px;
      }

      p {
        font-size: 20px;
        padding: 5px 0;
      }

      img {
        width: 250px;
        height: 250px;
        padding: 20px;
      }
    }

    .cards_column {
      display: flex;
      flex-direction: column;
    }

    .cards_reverse {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}